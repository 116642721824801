import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useForm, Controller  } from 'react-hook-form'
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
// import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import GlobalVariable from '../../../path/global';
import Loader from '../../../components/Loader/SpLoader';
import CircularLoader from '../../../components/Loader/circularloader';



const UserPrompts = ({ openSnackbar }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesToShow, setEntriesToShow] = useState(5); // Default value is 5
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [open, setOpen] = useState(false);

  const [editedItem, setEditedItem] = useState({
    promptName: '',
    promptDescription: '',
    userId: '',
  });


  const baseApiUrl = GlobalVariable.BASE_API_URL;

  const validationSchema = Yup.object().shape({
    promptName: Yup.string().required('Prompt Name is required'),
    promptDescription: Yup.string().required('Prompt Description is required'),
  });

  const { handleSubmit, formState: { errors }, setValue, control,clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: editedItem.id,
      userId: editedItem.userId,
      promptName: editedItem.promptName,
      promptDescription: editedItem.promptDescription,
      status: 1
      // Add other fields if needed
    },
  });

  const handleEditClick = (row) => {
    setEditedItem({
      id: row.id,
      promptName: row.promptName,
      promptDescription: row.promptDescription,
      userName: row.userName,
      userId: row.userId
    });
    
    setOpen(true);
    setValue('id', row.id);
    setValue('userId', row.userId);
    setValue('userName', row.userName);
    setValue('promptName', row.promptName);
    setValue('promptDescription', row.promptDescription);
  };
  const handleClose = () => {
    setOpen(false);
    Object.keys(errors).forEach((field) => {
      clearErrors(field)
    })
  };


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const locaUserId = JSON.parse(localStorage.getItem('userdetails'));
  const fetchPrompts = async () => {

    try {
      setIsLoading(true);
      const promptData = await axios.get(`${baseApiUrl}getUserPrompts`);

      const formattedItems = promptData.data.activeData.map(item => ({
        ...item,
        userName: item.userData?.name, // Assuming 'name' is the property you want
      }));
      setItems(formattedItems);
      setSearchData(formattedItems)
    }
    catch (error) {
      console.error('Error fetching prompt data:', error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    

    fetchPrompts();

  }, []);

  const onSubmit = async (data) => {

    try {
      // setIsLoading(true);

      const obj = {
        id: data.id,
        promptName: data.promptName,
        promptDescription: data.promptDescription,
        userId: data.userId,
        status: 1
      }


      // Perform the PUT request to update the prompt
      const res = await axios.put(`${baseApiUrl}updateUserPrompts/${data.id}`, obj);

      if(res){
          // Refresh the data after updating
          await fetchPrompts();
    
          // Close the dialog
          handleClose();
          openSnackbar('success', "User Prompt Updated Successfully");
          setIsLoading(true)
      }
    
    } catch (error) {
      console.error("Error:", error);

      let errorMessage = "An error occurred";
    
      if (error.response) {
        if (error.response.data) {
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          } else {
            errorMessage = JSON.stringify(error.response.data);
          }
        } else {
          errorMessage = JSON.stringify(error.response);
        }
      }
    
      if (error.response && error.response.data && error.response.data.status === 401) {
        openSnackbar('error', errorMessage);
      } else if (error.response && error.response.data && error.response.data.status_code === 422) {
        // Handle other specific status codes as needed
      } else {
        // Handle generic error
        openSnackbar('error', errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const columns = [

    (locaUserId.user.roleId === 1 || locaUserId.user.roleId === 4) && { field: 'userName', headerName: 'User Name', width:150, headerClassName: 'bg-primary text-white text-size' },
    { field: 'promptName', headerName: 'Prompt Name', width:200, headerClassName: 'bg-primary text-white text-size' },
    { field: 'promptDescription', headerName: 'Prompt Description', flex: 1, headerClassName: 'bg-primary text-white text-size' , renderCell: (params) => (
      <Tooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>} arrow>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      </Tooltip>
    )},

   

    // (locaUserId.user.roleId === 1 || (locaUserId.user.roleId === 4 && locaUserId.user.changePrompt === 1)) && {
      
      (locaUserId.user.roleId === 2 || locaUserId.user.roleId === 1 || ((locaUserId.user.roleId === 5 || locaUserId.user.roleId === 4) && locaUserId.user.changePrompt === 1)) && { field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <>
            <Tooltip title="Edit" arrow>
              <IconButton
                size="small"
                style={{ marginRight: '8px' }}
                onClick={() => handleEditClick(params.row)}
                className='button-a'
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        </div>
      ),
      headerClassName: 'bg-primary text-white text-size'
     },
  ].filter(Boolean); // Filter out falsy values, including undefined

  const getRowId = (row) => row.id;

  const handleFilter = (e) => {
    setSearchTerm(e);
    let data = searchData
    let filterit = filteritems(data, e)
    setItems(filterit)
    setCurrentPage(1)

  };

  const filteritems = (array, search) => {
    const lowSearch = search.toLowerCase()
    return array.filter(function (wine) {
      return Object.values(wine).some(val => String(val).toLowerCase().includes(lowSearch)
      )
    })
  }


  const CustomPagination = () => (
    <div style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
      <div style={{ float: 'left', margin: '8px 0 0 8px' }}>
        Showing {Math.min((currentPage - 1) * entriesToShow + 1, items.length)} to {Math.min(currentPage * entriesToShow, items.length)} of {items.length} entries
      </div>
      <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
        <Typography style={{ marginRight: '8px' }}>Rows</Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          value={entriesToShow}
          onChange={(e) => setEntriesToShow(e.target.value)}
          style={{ width: '160px' }} // Reduced the width
        >
          {[5, 10, 25, 50, 100].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Pagination
          color="primary"
          count={Math.ceil(items.length / entriesToShow)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          sx={{width: '100%'}}
        />
      </div>
    </div>
  );


  return (
    <div className="prompts" style={{ position: 'relative' }}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-end"  sx={{paddingTop: '10px'}}>
            <Grid xs={12} sm={3} md={6} container spacing={2} alignItems="center" justifyContent="flex-end" style={{ marginTop: '10px', paddingRight: '18px', paddingLeft: '18px' }}>
              <Box display="flex" alignItems="center">
                <Grid item sx={{marginBottom: '0px'}}>
                  
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => handleFilter(e.target.value)}
                      style={{ marginRight: '8px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
               
                </Grid>
              </Box>
            </Grid>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end" className='user-prompts' style={{ marginBottom: '16px', marginTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
              <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                 
                  <DataGrid
                    rows={items.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}
                    columns={columns}
                    pageSize={isSmallScreen ? 3 : 5}
                    autoHeight
                    getRowId={getRowId}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    pagination
                    paginationMode="server"
                    onPageChange={(params) => setCurrentPage(params.page)}
                    components={{
                            
                      NoRowsOverlay: () => (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.8)' }}>
                        {!isLoading && items.length === 0 ? (
                                    <Typography>No data available</Typography>
                        ) : (
                          isLoading && <Loader/>
                        )}
                      </div>
                      ),
                      Pagination: CustomPagination,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} />
      <Dialog open={open}  maxWidth="sm" fullWidth component={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)', left: '50%', top: '50%' }}>
            {isLoading && <CircularLoader />}
          </div>
        
        <DialogTitle sx={{ backgroundColor: '#1976d2', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px', fontSize: '20px' }}>
          <span>Edit User Prompts</span>
          {/* <IconButton onClick={handleClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          {/* { locaUserId.user.roleId === 1 && <TextField
            label="User Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="userId"
            value={editedItem.userName}
            InputProps={{
              readOnly: true,
            }}
          />} */}
        
        <Controller
                name="promptName"
                control={control}
                defaultValue={editedItem?.promptName || ''}
                render={({ field }) => (
                  <TextField
                    label="Prompt Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete="Off"
                    value={field.value}
                    onChange={(e) => {
                      setValue('promptName', e.target.value);
                      clearErrors('promptName');
                    }}
                    error={Boolean(errors.promptName)}
                    helperText={errors.promptName?.message}
                    required
                  />
                )}
              />

              <Controller
                name="promptDescription"
                control={control}
                defaultValue={editedItem?.promptDescription || ''}
                render={({ field }) => (
                  <TextField
                    label="Prompt Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    autoComplete="Off"
                    value={field.value}
                    onChange={(e) => {
                      setValue('promptDescription', e.target.value)
                      clearErrors('promptDescription');
                    }}
                    
                    error={Boolean(errors.promptDescription)}
                    helperText={errors.promptDescription?.message}
                    required
                  />
                )}
              />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="small"
            id='button-form'
          >
            Cancel
          </Button>
          <Button
          type='submit'
            color="primary"
            variant="contained"
            size="small"
            style={{ fontSize: '18px' }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserPrompts;