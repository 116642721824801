// JobDescription.jsx
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddJobModal from './AddJobModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios  from 'axios';
import { styled } from '@mui/material/styles';
import GlobalVariable from '../../path/global';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Loader from '../../components/Loader/SpLoader';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@mui/material';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const JobDescription = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesToShow, setEntriesToShow] = useState(10); // Initial value is set to 10
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const [selectedJobToDelete, setSelectedJobToDelete] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const baseApiUrl = GlobalVariable.BASE_API_URL
  const [rowsData, setRowsData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  // const [deleteMode, setDeleteMode] = useState('');
  
  const userDetails = JSON.parse(localStorage.getItem('userdetails'));
  // const companyId = userDetails.user.companyId;
  const userId = userDetails.user.roleId


  const [sortBy, setSortBy] = useState(''); 
  const [sortOrder, setSortOrder] = useState(''); 

 

  const [searchData, setSearchData] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
 
  const handleSetSnackbarMessage = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };


    
  }, []);

  const handleSortChange = (column) => {
    // Toggle sorting order if the same column is clicked again
    if (sortBy === column && sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }

    setSortBy(column);

    // Sort the existing data client-side
    const sortedData = [...searchData].sort((a, b) => {
      const sortA = a[column];
      const sortB = b[column];

      if (sortA < sortB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (sortA > sortB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setRowsData(sortedData);

  };

  const fetchData = async () => {
    try {
      setIsLoading(true); 
      const response = await axios.get(`${baseApiUrl}getJobs`);
      const jobsData = response.data.activeData
      
      const usersDat = jobsData.map(item => {
        let jdQuestions = {
          "Technical Skills Questions": [],
          "Behavioral Questions": [],
          "Cultural Fit Questions": [],
          "Project Experience Questions": [],
          "Soft Skills Questions": []
        };
        if (item.JdQuestions) {
          try {
              jdQuestions = JSON.parse(item.JdQuestions);
          } catch (error) {
              console.error("Error parsing JDQuestions:", error);
              jdQuestions = {};
          }
      }
        return{
        id: item.id,
        jobId: item.jobId,
        jobName: item.jobId +'-'+ item.jobName,
        jobDescription: item.jobDescription,
        clientName: item.clientName,
        ClientIndustry: item.ClientIndustry,
        createdAt: getSelectedDate(item.createdAt),
        JDQuestions: jdQuestions
      }});
      setSearchData(usersDat)
      setRowsData(usersDat); 
    console.log(usersDat, "checking userJ")
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
      fetchData();
  }, [baseApiUrl])
  
  const reloadData = () => {
    fetchData();
  }

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
 

  const getSelectedDate = (date) => {
     if (date) {
         var today = new Date(date);
         var data = '';
         var hours = today.getHours();
         var minutes = today.getMinutes();
         var seconds = today.getSeconds();
         var ampm = hours >= 12 ? 'PM' : 'AM';
 
         // Convert hours to 12-hour format
         hours = hours % 12;
         hours = hours ? hours : 12; // Handle midnight (0 hours)
 
         // Formatting time to have leading zeros if necessary
         var formattedTime = [
             (hours < 10 ? '0' : '') + hours,
             (minutes < 10 ? '0' : '') + minutes,
             (seconds < 10 ? '0' : '') + seconds
         ].join(':');
 
         data = today.getDate() + '-' + monthNames[today.getMonth()] + '-' + today.getFullYear() + ' ' + formattedTime + ' ' + ampm;
         if (data) {
             return data;
         } else {
             return data;
         }
     }
 }

  const columns = [
    { field: 'jobName', headerName: 'Job Id - Name', headerClassName: 'bg-primary text-white text-size',  sortable: true,
    onClick: () => handleSortChange('jobName'), width: 250, minHeight : '45px',maxHeight : '45px'},

    { field: 'clientName', headerName: 'Client Name', headerClassName: 'bg-primary text-white text-size',  sortable: true,
    onClick: () => handleSortChange('clientName'), width: 250, minHeight : '45px',maxHeight : '45px', renderCell:(params) => (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {params.row.clientName}
      </div>
    )},

    { field: 'ClientIndustry', headerName: 'Client Industry', headerClassName: 'bg-primary text-white text-size',  sortable: true,
    onClick: () => handleSortChange('ClientIndustry'), width: 250, minHeight : '45px',maxHeight : '45px', renderCell:(params) => (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {params.row.ClientIndustry}
      </div>
    )},

    { field: 'jobDescription', headerName: 'Job Description', flex: 1, headerClassName: 'bg-primary text-white text-size',  sortable: true,
    onClick: () => handleSortChange('jobDescription'), minHeight : '45px',maxHeight : '45px', renderCell: (params) => (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {params.row.jobDescription}
      </div>
    )},
    { field: 'createdAt', headerName: 'Posted Date', width: 250, headerClassName: 'bg-primary text-white text-size',  sortable: true,
    onClick: () => handleSortChange('createdDate'), minHeight : '45px',maxHeight : '45px', renderCell: (params) => (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {params.row.createdAt}
      </div>
    )},
 {
      field: 'actions',
      headerName: 'Actions',
      minHeight : '45px', maxHeight : '45px',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="View Questions" arrow>
            <IconButton
                size="small"
                onClick={() =>  handleViewClick(params.row)}
                style={{ marginRight: '8px' }}
                className='button-a'
              >
                 <VisibilityIcon color="primary" />
              </IconButton>
          </Tooltip> 
          <Tooltip title="Edit" arrow>
            <IconButton
                size="small"
                onClick={() =>  handleEditClick(params.row)}
                style={{ marginRight: '8px' }}
                className='button-a'
              >
                 <EditIcon color="primary" />
              </IconButton>
          </Tooltip>
          
 
              {isSmallScreen && (userId === 1 || userId === 2 || userId === 4 || userId === 5) && (
        <Tooltip title="Delete" arrow>
          <IconButton
            size="small"
            onClick={() => handleDeleteClick(params.row.id)}
            className='button-a'
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      )}

      {!isSmallScreen && (userId === 1 || userId === 2 || userId === 4 || userId === 5) && (
        <IconButton
          size="small"
          onClick={() => handleDeleteClick(params.row.id)}
          className='button-a'
        >
          <DeleteIcon color="primary" />
        </IconButton>
      )}
    </>
  ),
  headerClassName: 'bg-primary text-white text-size',
    },
  ];

  const getRowId = (row) => row.id;

  


  const CustomPagination = () => (
    <div style={{ width:'100%', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ float:'left', margin:'8px 0 0 8px' }}>
        Showing {Math.min((currentPage - 1) * entriesToShow + 1, rowsData.length)} to {Math.min(currentPage * entriesToShow, rowsData.length)} of {rowsData.length} entries
      </div>
      <div style={{ display: 'flex', alignItems: 'center' ,float:'right'}}>
        <Typography style={{ marginRight: '8px' }}>Rows</Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          value={entriesToShow}
          onChange={(e) => setEntriesToShow(e.target.value)}
        >
          {[5, 10, 20].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Pagination
          color="primary"
          count={Math.ceil(rowsData.length / entriesToShow)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
        />
      </div>
    </div>
  );
  
  const handleAddModalOpen = () => {
    setAddModalOpen(true);
    setSelectedJobData(null);
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  const handleEditClick = (rowData) => {
    console.log(rowData,"row")
    setSelectedJobData(rowData);
    setAddModalOpen(true);
  };

  const handleViewClick = (rowData) => {
    const questions = rowData.JDQuestions
    console.log(questions)
    setSelectedQuestions(questions);
    setOpenModal(true);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedQuestions([]);
  };



  // const handleSaveNewJob = (newJobName, newJobDescription) => {
  //   const newJob = { id: generateUniqueId(), jobName: newJobName, jobDescription: newJobDescription };
  //   setRowsData((prevRows) => [...prevRows, newJob]);
  //   handleAddModalClose();
  // };

  // const handleSaveEditJob = (originalJobName, newJobName, newJobDescription) => {
  //   setRowsData((prevRows) =>
  //     prevRows.map((row) =>
  //       row.jobdescriptionName === originalJobName
  //         ? { jobdescriptionName: newJobName, jobDescription: newJobDescription }
  //         : row
  //     )
  //   );
  //   handleEditModalClose();
  // };

  const handleDeleteClick = (jobName) => {
    setSelectedJobToDelete(jobName);

    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedJobToDelete(null);
    setDeleteModalOpen(false);
  };



  const handleFilter = (e) => {
    setSearchTerm(e);
    let data = searchData
    let filterit = filteritems(data,e)
    setRowsData(filterit)
    setCurrentPage(1)
   
  };

  const filteritems = (array, search) => {
    const lowSearch = search.toLowerCase()
    return array.filter(function (wine) {
        return Object.values(wine).some(val => String(val).toLowerCase().includes(lowSearch)
        )
    })
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1000,
  },
});

  return (
    <Box className="job-desss">
         <div>
         <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Adjust duration as needed
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
 
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Card variant="outlined">
        <CardContent>
          <Box component={'form'}>
          <Grid container className="job-content" spacing={2} alignItems="center" justifyContent="flex-end" sx={{paddingTop: '8px'}}>
            <Grid item >
              <Tooltip title="Search Job Descriptions" arrow>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={(e) => handleFilter(e.target.value)}
                  style={{ marginRight: '8px' }}
                />
              </Tooltip>
            </Grid>
            <Grid item  className='grid-btn'>
              <Tooltip title="Add Job Description" arrow>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ borderRadius: '10px', marginLeft: '8px' }}
                  onClick={handleAddModalOpen}
                  className='job_button_view button-t'
                >
                  Add Job
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: '100%' }}>
                <DataGrid
                 rows={rowsData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}
                 columns={columns.map((column) => {
                  if (column.field === 'jobDescription') {
                    return {
                      ...column,
                      renderCell: (params) => (
                        
                     <CustomWidthTooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>}>
                     <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</div>
                    </CustomWidthTooltip>
                      ),
                    };
                  }
                  return column;
                })}
                 

                  // columns={columns}
                  pageSize={entriesToShow}
                  autoHeight
                  getRowId={getRowId}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  pagination
                  paginationMode="server"
                  
                  onPageChange={(params) => setCurrentPage(params.page)}
                  sortingMode="none"                  components={{
                            
                    NoRowsOverlay: () => (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.8)' }}>
                      {isLoading && (
                        <Loader/>
                      )}
                    </div>
                    ),
                    Pagination: CustomPagination,
                  }}
                />
              </div>
            </Grid>
          </Grid>
          </Box>
         
        </CardContent>
      </Card>

      <AddJobModal open={isAddModalOpen} 
      onClose={handleAddModalClose} 
      onDeleteClose = {handleDeleteModalClose}
      // onSave={handleSaveNewJob}
      reloadData = {reloadData}
      initialData={selectedJobData}
      deleteOpen = {isDeleteModalOpen}
      jobName={selectedJobToDelete}
      // deleteMode={deleteMode}
      />
      {/* {selectedJobToDelete && (
        <DeleteJobModal
          open={isDeleteModalOpen}
          onClose={handleDeleteModalClose}
          onDelete={handleDeleteJob}
          jobName={selectedJobToDelete}
          setSnackbarMessage={handleSetSnackbarMessage}
          reloadData={reloadData}
        />
      )} */}
    </div>
    <Dialog open={openModal} 
    onOpenChange={setOpenModal} 
    fullScreen={fullScreen}
    TransitionComponent={Transition} 
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",  
        },
      },
    }}
    aria-describedby="alert-dialog-slide-description" 
    onClose={handleCloseModal}>
      <DialogTitle>
                  <Typography variant='h6' className='dialog-title bottom-line'>  JD Based Questions</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    className='bg-primary'
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
      <DialogContent>
      <DialogContentText>
    <div className="p-4 space-y-3 max-h-96 overflow-auto">
        {selectedQuestions && Object.keys(selectedQuestions).length > 0 ? (
            Object.entries(selectedQuestions).map(([category, questions]) => (
                <Accordion key={category}>
                    <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                        <h3 className="font-bold text-lg text-gray-700">{category}</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        {questions.length > 0 ? (
                            questions.map((question, index) => (
                                <div key={index} className="p-2 border-b" style={{ background: '#f3f3f3', padding: '15px', borderRadius: '15px', marginBottom: '10px', color: '#1976d2' }}>
                                    {question}
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">No questions available in this category.</p>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))
        ) : (
            <p style={{ padding: '25px', fontSize: '30px', textAlign: 'center' }}>No questions available.</p>
        )}
    </div>
</DialogContentText>
        
      
      </DialogContent>
      <DialogActions>
        <div className="flex justify-end">
          <Button color="primary" variant="contained" size="small" style={{ fontSize: '18px' }} onClick={handleCloseModal}>Close</Button>
        </div>
        </DialogActions>
    </Dialog>
    </Box>
   
  );
};

export default JobDescription;
