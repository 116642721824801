import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import SelectDropDown from "../../components/reusableComponents/selectDropDown";
import FileUploads from "../../components/reusableComponents/fileuploads";
import { CardContent, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as yup from "yup";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import Alert from "@mui/material/Alert";
// import Loader from '../../components/Loader/SpLoader';
import BuildLoader from "../../components/Loader/buildloader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import GlobalVariable from "../../path/global";

const baseApiUrl = GlobalVariable.BASE_API_URL;
const baseApiGlobalUrl = GlobalVariable.BASE_API_GOOGLE_URL;

const validationSchema = yup.object().shape({
  JobDetails: yup.string().required("Please select a job"),
  Files: yup
    .array()
    .min(1, "Please upload at least one file")
    .required("Please upload at least one file"),
});

const ProfileMatchingPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesToShow, setEntriesToShow] = useState(10); // Initial value is set to 10
  const [JobDetails, setJobDetails] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [isJobSelected, setIsJobSelected] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [jobDes, setJobDes] = useState([]);
  const [promptData, setPromptData] = useState("");
  const [analysisData, setAnalysisData] = useState([]);
  // const [base64arraydata, setBase64ArrayData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);


  const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
  const [pdfPreviewProfileName, setPdfPreviewProfileName] = useState('');


  const userDetails = JSON.parse(localStorage.getItem("userdetails"));

  const saveBase64AsPDF = (base64Data, fileName) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);
    setPdfPreviewUrl(pdfUrl);
    setPdfPreviewOpen(true);
    setPdfPreviewProfileName(fileName.replace('_Profile_Analysis_Report.pdf', ''));

    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = fileName;
    // link.click();

  };

  const handlePdfPreviewClose = () => {
    setPdfPreviewOpen(false);
    setPdfPreviewUrl('');
    setPdfPreviewProfileName('');

  };

  const PdfPrintPreview = () => {
    return (
      <Dialog open={pdfPreviewOpen} onClose={handlePdfPreviewClose} maxWidth="lg" fullWidth >
        <DialogTitle sx={{paddingLeft: '2px !important'}}>{`Resume Analysis Report - ${pdfPreviewProfileName}`}</DialogTitle>
        <DialogContent sx={{height: '700px', overflow: 'hidden', padding: 0}} >
          <iframe
            title="PDF Print Preview"
            src={pdfPreviewUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePdfPreviewClose}  variant="outlined"
          size="small"
          id='button-form'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const savePDFFiles = async (files, type) => {
    const userName = files.name
    let base64file;
    if(type === 'analysedReport'){
      base64file = files.analysisReport
    }else if(type === 'missingSkills'){
      base64file = files.skillsAnalysis
    }
    if (files) {
      const fileName = type === 'analysedReport' 
                    ? `${userName}_Resume_Analysis_Report.pdf` 
                    : `${userName}_Missing_Skills_Report.pdf`;
      saveBase64AsPDF(base64file, fileName, userName);
    }
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const handleMatchProfiles = () => {

    setAnalysisData([]);

    // Check if a job is selected
    if (!isJobSelected) {
      setSnackbarMessage("Please select a job id-Name");
      setSnackbarSeverity("error");
        setSnackbarOpen(true);

      return;
    }
    if (
      !selectedFile1 &&
      !selectedFile2 &&
      !selectedFile3 &&
      !selectedFile4 &&
      !selectedFile5
    ) {
      setSnackbarMessage("Please upload at least one Resume");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    // Validate form data
    validationSchema
      .validate({
        JobDetails: JobDetails,
        Files: [
          selectedFile1,
          selectedFile2,
          selectedFile3,
          selectedFile4,
          selectedFile5,
        ],
      })
      .then(async () => {
        setSnackbarMessage("");
        setSnackbarMessage("");
        

        // Convert selected files to Base64
        const convertFilesToBase64 = async () => {
          const base64Array = [];
          for (const file of [
            selectedFile1,
            selectedFile2,
            selectedFile3,
            selectedFile4,
            selectedFile5,
          ]) {
            if (file) {
              const base64Data = await readFileAsBase64(file);
              base64Array.push(base64Data);
            }
          }
          return base64Array;
        };

        let y = jobDes.find((x) => x.value === JobDetails);

        // Prepare data for server
        const dataObject = {
          jobId: y.value,
          jobName: y.label,
          jobDescription: y.jobdes,
          promptData: promptData,
          Files: await convertFilesToBase64(),
          userId: userDetails.user.id,
          companyId: userDetails.user.companyId,
        };


        // console.log(userDetails, "--------------------------- User Details")

        // savePDFFiles();
        // Send data to the server using Axios
        try {
          setIsLoading(true);
          const response = await axios.post(
            `${baseApiGlobalUrl}job_analysis/profilematch`,
            dataObject
          );
          if (response) {
            const analysisRes = response.data;
            const fetchFiles = analysisRes.profile_match_list;
            
            for (let n = 0; n < fetchFiles.length; n++) {
              const element = fetchFiles[n];
              element.base64Data = [element.analysisReport];
              element.jobdesss = analysisRes.jobName;
              element.email = element.email_address;
              element.uName = element.name;
              element.adate = analysisRes.analysisDate;
              element.id = n + 1;
              element.score = element.matchScore;
              element.anaReport = `${element.uName}_Resume_Analysis_Report.pdf`;
            }

            // console.log(fetchFiles, "12222222222222");
            setAnalysisData(fetchFiles);
            handleSnackbarOpen(
              "Profile Match Successfully completed",
              "success"
            );
          }
        } catch (error) {
          // console.error('Error sending data to the server:', error);
          handleSnackbarOpen(
            "Internal server error or prompt description not matched for the requirment",
            "error"
          );
        } finally {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.path === "JobDetails") {
          setSnackbarMessage(error.message);
        } else if (error.path === "Files") {
          setSnackbarMessage(error.message);
        }
      });
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    const localStorageData = localStorage.getItem("userdetails");

    // Now userData is a JavaScript object, and you can access properties like user, token_type, and access_token
    // Fetch data from your API endpoint
    const fetchData = async () => {
      try {
        if (localStorageData) {
          const userData = JSON.parse(localStorageData);

          if (userData && userData.user) {
            const companyId = userData.user.companyId;

            const response = await axios.get(
              `${baseApiUrl}getJobs`
            );

            const jobsData = response.data.activeData;
            const formattedOptions = jobsData.map((item) => ({
              value: item.id,
              label: item.jobId + ' - ' + item.jobName,
              jobdes: item.jobDescription,
            }));

            setJobDes(formattedOptions);
          } else {
            console.error("userData or userData.user is null or undefined");
          }
        } else {
          console.error("localStorageData is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getPromptData = async () => {
      try {
        const localStorageData = localStorage.getItem("userdetails");
        if (localStorageData) {
          const userData = JSON.parse(localStorageData);
          if (userData && userData.user) {
            const userId = {
              userId: userData?.user.id,
            };
            const promptData = await axios.get(
              `${baseApiUrl}getUserSelfPrompts`
            );

            const prompts = promptData.data.activeData;

            const modifiedPrompts = prompts.map(prompt => {
              // Create a copy of the prompt object without the "id" field
              const { id, ...newPrompt } = prompt;
              return newPrompt;
          });
            setPromptData(modifiedPrompts);
          } else {
            console.error("userData or userData.user is null or undefined");
          }
        } else {
          console.error("localStorageData is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching prompt data:", error);
      }
    };

    getPromptData();
    fetchData();
  }, []);

  const handleJobChange = (event) => {
    const selectedJob = event.value;
    setJobDetails(selectedJob);
    let x = jobDes.find((x) => x.value === selectedJob).jobdes;

    setJobDescription(x);
    //  setJobDescription(jobDescriptionMap[selectedJob] || '');
    setIsJobSelected(true);
    setSelectedFile1(null);
    setSelectedFile2(null);
    setSelectedFile3(null);
    setSelectedFile4(null);
    setSelectedFile5(null);




  };

  const handleFileChange = (fileNumber, selectedFiles) => {
    // Check if a job is selected before updating file state
    if (!isJobSelected) {

      return;
    }

    switch (fileNumber) {
      case 1:
        setSelectedFile1(selectedFiles);
        break;
      case 2:
        setSelectedFile2(selectedFiles);
        break;
      case 3:
        setSelectedFile3(selectedFiles);
        break;
      case 4:
        setSelectedFile4(selectedFiles);
        break;
      case 5:
        setSelectedFile5(selectedFiles);
        break;
      default:
        break;
    }
  };
  const getRowId = (row) => row.id;

  const columns = [
    {
      field: "jobdesss",
      headerName: "Job Id-Name",
      width:150,
      headerClassName: "bg-primary text-white text-size",
    },
    {
      field: "uName",
      headerName: "Profile Name",
      width: 150,
      headerClassName: "bg-primary text-white text-size",
    },
    {
      field: "email",
      headerName: "Profile Email",
      flex: 1,
      headerClassName: "bg-primary text-white text-size",
    },
    {
      field: "matchScore",
      headerName: "Overall Match Score",
      width: 250,
      headerClassName: "bg-primary text-white text-size",
    },
    {
      field: "anaReport",
      headerName: "Analysed Report",
      width: 350,
      headerClassName: "bg-primary text-white text-size",
      renderCell: (params) => (
        <div
          onClick={() => savePDFFiles(params.row, 'analysedReport')}
          className="profile-match-btn"
        >
          {`${params.row.anaReport}`}
        </div>
      ),
    },
    {
      field: 'missingSkillsReport',
      headerName: 'Missing Skills',
      headerClassName: 'bg-primary text-white text-size',
      width: 330,
      renderCell: (params) => (
        <div
          onClick={() =>
           
             savePDFFiles(params.row, 'missingSkills')}
          className='profile-match-btn'
        >
          {`${params.row.uName}_resume_analysed_missing_skills`}
        </div>
      ),
    },
    {
      field: "adate",
      headerName: "Report Date",
      flex: 1,
      headerClassName: "bg-primary text-white text-size",
    },
  ]
  const CustomPagination = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "8px",
      }}
    >
      <div style={{ float: "left", margin: "8px 0 0 8px" }}>
      Showing {Math.min((currentPage - 1) * entriesToShow + 1, analysisData.length)} to {Math.min(currentPage * entriesToShow, analysisData.length)} of {analysisData.length} entries

      </div>
      <div>
        {/* Removed Showing 1 to {Math.min(currentPage * (isSmallScreen ? 3 : 5), filteredRows.length)} of {filteredRows.length} entries */}
      </div>
      <div style={{ display: "flex", alignItems: "center", float: "right" }}>
        <Typography style={{ marginRight: "8px" }}>Rows</Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          value={entriesToShow}
          onChange={(e) => setEntriesToShow(e.target.value)}
          // style={{ width: '60px' }} // Reduced the width
        >
          {[5, 10, 20].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {/* <Typography style={{ margin: '0 8px' }}>Entries</Typography> */}
        <Box>
          <Pagination
            color="primary"
            count={Math.ceil(analysisData.length / entriesToShow)}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
          />
        </Box>
      </div>
    </div>
  );

  return (
    <div style={{position: 'relative'}}>
         {isLoading && (
        <div className="pro-loader">
            <BuildLoader className ="loader-pro"/>
            
        </div>
            
          )}
      <Box sx={{display: 'flex', width: '100%'}}>
        <Card variant="outlined" sx={{width: '100%'}}>
          <CardContent>
              <Grid  container spacing={2} >
                  <Grid container item xs={12} sm={12} md={12} lg={4}>
                    <Grid item xs={12}>
                      <Typography variant="h3" sx={{marginBottom: '10px !important'}}>Select Job Id-Name</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{paddingTop: '10px'}}>
                      <SelectDropDown
                        valueData={jobDes}
                        value={JobDetails}
                        onJobChange={handleJobChange}
                        placeholder="Select Job Id-Name"
                      />

                    </Grid>
                    <Grid item xs={12} sx={{paddingTop: '10px'}}>
                        <TextField
                          margin="dense"
                          id="JobDescription"
                          label="Job Description"
                          value={jobDescription}
                          fullWidth
                          multiline
                          rows={4}
                          sx={{ width: "100%" }}
                          disabled
                        />

                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} lg={8}>
                    <Grid item xs={12} spacing={2}>
                      <Typography variant="h3" sx={{marginBottom: '10px !important'}}>Upload Resumes</Typography>
                    
                      <Grid container spacing={2} columns={12} sx={{paddingTop: '10px'}}>
                        {[1, 2, 3, 4, 5].map((fileNumber) => (
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={fileNumber}>
                            <FileUploads
                              className="fileUpload"
                              onFileChange={(selectedFiles) =>
                                handleFileChange(fileNumber, selectedFiles)
                              }
                              files={
                                fileNumber === 1
                                  ? selectedFile1
                                  : fileNumber === 2
                                  ? selectedFile2
                                  : fileNumber === 3
                                  ? selectedFile3
                                  : fileNumber === 4
                                  ? selectedFile4
                                  : selectedFile5
                              }
                              disabled={!isJobSelected}
                            />
                          </Grid>
                        ))}
                      </Grid>
                </Grid>
                  </Grid>
                  <Grid container item paddingLeft={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          variant="contained"
                          disableElevation
                          onClick={handleMatchProfiles}
                        >
                            Profile Analysis
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
              </Grid>
          </CardContent>
        </Card>

        
      </Box>
      <Box>
      <div style={{ marginTop: "10px" }}>
        <Card variant="outlined" marginTop={3}>
          <Grid container paddingLeft={1} paddingRight={1} marginTop={1}>
            <Grid item xs={12} sm={6} md={6} padding={1}>
              <h3>Recent Profile Analysis</h3>
            </Grid>
            <Grid item xs={12} sm={12} paddingTop={1} marginBottom={2}>
              <DataGrid
                // rows={analysisData}
                id="profile_table_view"
                rows={analysisData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}

                columns={columns}
                pageSize={entriesToShow}
                autoHeight
                getRowId={getRowId}
                disableRowSelectionOnClick
                disableColumnMenu
                pagination
                paginationMode="server"
                onPageChange={(params) => setCurrentPage(params.page)}
                components={{
                  Pagination: CustomPagination,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}></Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            severity={snackbarSeverity}
          >
            <Alert
              onClose={handleClose}
              style={{
                backgroundColor:
                  snackbarSeverity === "success" ? "#43a047" : "#d32f2f",
              }}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Card>
      </div>
      </Box>
      <PdfPrintPreview />
    </div>
    
    // <div style={{position:'relative'}}>
      //  {isLoading && (
      //   <div className="pro-loader">
      //       <Loader className ="loader-pro"/>
      //   </div>
            
      //     )}
    //   <Card variant="outlined">
    //     <div>
         
    //       <Grid
    //         container
    //         spacing={3}
    //         alignItems="center"
    //         className="profileMatch"
    //         sx={{ marginBottom: "10px" }}
    //       >
    //         <Grid item xs={12} sm={12} md={4}>
    //           <h3>Profile Match</h3>
    //         </Grid>
    //         <Grid item xs={12} sm={12} md={8}>
    //           <h3>Upload Resumes</h3>
    //         </Grid>
    //       </Grid>
    //       <Grid
    //         container
    //         spacing={2}
    //         className="profile-fileUpload"
    //         display={'flex'}
    //       >
    //         <Grid item xs={12} sm={12} md={4}>
    //           <SelectDropDown
    //             valueData={jobDes}
    //             value={JobDetails}
    //             onJobChange={handleJobChange}
    //             placeholder="Select JOB"
    //           />

              // <TextField
              //   margin="dense"
              //   id="JobDescription"
              //   label="Job Description"
              //   value={jobDescription}
              //   fullWidth
              //   multiline
              //   rows={4}
              //   sx={{ width: "100%" }}
              //   disabled
              // />
    //         </Grid>

    //         <Grid container item xs={8}>
    //           <Grid item xs={8}>
              // {[1, 2].map((fileNumber) => (
              //   <Grid key={fileNumber} item xs={5}>
              //     <FileUploads
              //       className="fileUpload"
              //       onFileChange={(selectedFiles) =>
              //         handleFileChange(fileNumber, selectedFiles)
              //       }
              //       files={
              //         fileNumber === 1
              //           ? selectedFile1
              //           : selectedFile2
              //       }
              //       disabled={!isJobSelected}
              //     />
              //   </Grid>
              //   ))}
    //           </Grid>
    //           <Grid item xs={8}>
    //           {[3, 4].map((fileNumber) => (
    //             <Grid key={fileNumber} item xs={5}>
    //               <FileUploads
    //                 className="fileUpload"
    //                 onFileChange={(selectedFiles) =>
    //                   handleFileChange(fileNumber, selectedFiles)
    //                 }
    //                 files={
    //                   fileNumber === 3
    //                     ? selectedFile3
    //                     : selectedFile4
    //                 }
    //                 disabled={!isJobSelected}
    //               />
    //             </Grid>
    //           ))}
    //           </Grid>
              
    //         </Grid>
            
    //       </Grid>


          // <Grid container padding={2} paddingLeft={2}>
          //   <Grid item xs={12} sm={12} md={12}>
          //     <div style={{ display: "flex", justifyContent: "flex-end" }}>
          //       <Button
          //         variant="contained"
          //         disableElevation
          //         onClick={handleMatchProfiles}
          //       >
          //         Match Profiles
          //       </Button>
          //     </div>
          //   </Grid>
          // </Grid>
    //     </div>
    //   </Card>

      // <div style={{ marginTop: "10px" }}>
      //   <Card variant="outlined" marginTop={3}>
      //     <Grid container paddingLeft={1} paddingRight={1} marginTop={1}>
      //       <Grid item xs={6} sm={6} md={6} padding={1}>
      //         <h3>Recent Profile Matches</h3>
      //       </Grid>
      //       <Grid item xs={12} sm={12} paddingTop={1} marginBottom={2}>
      //         <DataGrid
      //           // rows={analysisData}
      //           rows={analysisData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}

      //           columns={columns}
      //           pageSize={entriesToShow}
      //           autoHeight
      //           getRowId={getRowId}
      //           disableRowSelectionOnClick
      //           disableColumnMenu
      //           pagination
      //           paginationMode="server"
      //           onPageChange={(params) => setCurrentPage(params.page)}
      //           components={{
      //             Pagination: CustomPagination,
      //           }}
      //         />
      //       </Grid>
      //       <Grid item xs={12} sm={12} md={12}></Grid>
      //     </Grid>
      //     <Snackbar
      //       anchorOrigin={{ vertical: "top", horizontal: "right" }}
      //       open={snackbarOpen}
      //       autoHideDuration={6000}
      //       onClose={handleSnackbarClose}
      //     >
      //       <Alert
      //         onClose={handleClose}
      //         style={{
      //           backgroundColor:
      //             snackbarSeverity === "success" ? "#43a047" : "#d32f2f",
      //         }}
      //         variant="filled"
      //         sx={{ width: "100%" }}
      //       >
      //         {snackbarMessage}
      //       </Alert>
      //     </Snackbar>
      //   </Card>
      // </div>
    // </div>
  );
};

export default ProfileMatchingPage;
