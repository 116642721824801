import React, {useState, useEffect} from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import SelectDropDown from "../../components/reusableComponents/selectDropDown";
import GlobalVariable from '../../path/global'
import Loader from '../../components/Loader/SpLoader';
import CircuarLoader from '../../components/Loader/circularloader'
import { FormControl } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';

const baseApiUrl = GlobalVariable.BASE_API_URL

const PreviousReport = () => {
    
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesToShow, setEntriesToShow] = useState(10); // Initial value is set to 10
    const [searchTerm, setSearchTerm] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
    const [pdfPreviewProfileName, setPdfPreviewProfileName] = useState('');
    const [dataLaoding, setdataLoading] = useState(false)
  
  
    const userData = JSON.parse(localStorage.getItem('userdetails'));
    const userId = userData.user.id
    const companyId = userData.user.companyId

    useEffect(() => {
        const fetchData = async () => {
          try {
            const fetchJobs = await axios.get(`${baseApiUrl}getJobs`)
            const getJoblist = fetchJobs.data.activeData;

            const options = getJoblist.map((job) => ({
              value: job.id,
              label: job.jobId + '-' + job.jobName,
            }));
        
            setJobs(options);

          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
          }
        };
    
        fetchData();
      }, [baseApiUrl, companyId, userId]);


      const handleFilter = (e) => {
        setSearchTerm(e);
        let data = searchData
        let filterit = filteritems(data,e)
        setRowsData(filterit)
        setCurrentPage(1)
       
      };
    
      const filteritems = (array, search) => {
        const lowSearch = search.toLowerCase()
        return array.filter(function (wine) {
            return Object.values(wine).some(val => String(val).toLowerCase().includes(lowSearch)
            )
        })
    }
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
 

   const getSelectedDate = (date) => {
      if (date) {
          var today = new Date(date);
          var data = '';
          var hours = today.getHours();
          var minutes = today.getMinutes();
          var seconds = today.getSeconds();
          var ampm = hours >= 12 ? 'PM' : 'AM';
  
          // Convert hours to 12-hour format
          hours = hours % 12;
          hours = hours ? hours : 12; // Handle midnight (0 hours)
  
          // Formatting time to have leading zeros if necessary
          var formattedTime = [
              (hours < 10 ? '0' : '') + hours,
              (minutes < 10 ? '0' : '') + minutes,
              (seconds < 10 ? '0' : '') + seconds
          ].join(':');
  
          data = today.getDate() + '-' + monthNames[today.getMonth()] + '-' + today.getFullYear() + ' ' + formattedTime + ' ' + ampm;
          if (data) {
              return data;
          } else {
              return data;
          }
      }
  }

  
      const columns = [
        { field: 'jobdesss', headerName: 'Job Id-Name', headerClassName: 'bg-primary text-white text-size', width: 250 },
        { field: 'uName', headerName: 'Profile Name', headerClassName: 'bg-primary text-white text-size', width: 180 },
        { field: 'pemail', headerName: 'Email', headerClassName: 'bg-primary text-white text-size', width: 230},
        { field: 'matchScore', headerName: 'Overall Match Score', headerClassName: 'bg-primary text-white text-size', width: 180},
        
        // { field: 'profile', headerName: 'Profile', flex: 1, headerClassName: 'bg-primary text-white text-size' },
        {
          field: 'anaReport',
          headerName: 'Analysed Report',
          headerClassName: 'bg-primary text-white text-size',
          width: 330,
          renderCell: (params) => (
            <div
              onClick={() =>
               
                 savePDFFiles(params.row, 'analysedReport')}
              className='profile-match-btn'
            >
              {`${params.row.uName}_resume_analysed_report`}
            </div>
          ),
        },
        {
          field: 'missingSkillsReport',
          headerName: 'Missing Skills',
          headerClassName: 'bg-primary text-white text-size',
          width: 330,
          renderCell: (params) => (
            <div
              onClick={() =>
               
                 savePDFFiles(params.row, 'missingSkills')}
              className='profile-match-btn'
            >
              {`${params.row.uName}_resume_analysed_missing_skills`}
            </div>
          ),
        },
        { field: 'analysedBy', headerName: 'Analysed By', headerClassName: 'bg-primary text-white text-size analysis-report-date', width: 180 },
        { field: 'adate', headerName: 'Report Date', headerClassName: 'bg-primary text-white text-size analysis-report-date', width: 180 },
      ];

      const getRowId = (row) => row.id;

    
    const saveBase64AsPDF = (base64Data, fileName) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
        setPdfPreviewUrl(pdfUrl);
        setPdfPreviewOpen(true);
        setPdfPreviewProfileName(fileName.replace('_Resume_Analysis_Report.pdf', ''));
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob);
        // link.download = fileName;
        // link.click();
      };

      const handlePdfPreviewClose = () => {
        setPdfPreviewOpen(false);
        setPdfPreviewUrl('');
        setPdfPreviewProfileName('');
    
      };

      const PdfPrintPreview = () => {
        return (

          <Dialog open={pdfPreviewOpen} onClose={handlePdfPreviewClose} maxWidth="lg" fullWidth >
           
            
            <DialogTitle sx={{paddingLeft: '2px !important'}}>{`Resume Analysis Report - ${pdfPreviewProfileName}`}</DialogTitle>
            <DialogContent sx={{height: '700px', overflow: 'hidden', padding: 0}} >
              <iframe
                title="PDF Print Preview"
                src={pdfPreviewUrl}
                style={{ width: '100%', height: '100%' }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePdfPreviewClose}  variant="outlined"
              size="small"
              id='button-form'>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      };


      const fetchreportData = async (jobId) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${baseApiUrl}getOldMatch/${jobId}`);
            const newData = response.data.activeData;
            
            for (let n = 0; n < newData.length; n++) {
                const element = newData[n];
                element.jobdesss = element.jobName 
                element.pemail = element.email
                element.uName = element.userName 
                element.adate = getSelectedDate(element.analysisDate) 
                element.analysedBy = element.userData.name
            }
           
            setRowsData(newData); // Update the state with the fetched data
            setSearchData(newData)
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const savePDFFiles = async (files, type) => {
      setdataLoading(true)
      let response;
      if(type === 'analysedReport'){
        response = await axios.get(`${baseApiUrl}getPdfOldMatch/${files.id}?type=analysedReport`);
      }else if (type === 'missingSkills'){
        response = await axios.get(`${baseApiUrl}getPdfOldMatch/${files.id}?type=missingSkills`);
      }
      console.log(response)
 
      if (response){
        const userName = files.uName
        const base64file = response.data.activeData

      if (files) {
        const fileName = type === 'analysedReport' 
                    ? `${userName}_Resume_Analysis_Report.pdf` 
                    : `${userName}_Missing_Skills_Report.pdf`;
        saveBase64AsPDF(base64file, fileName);

      }
      setdataLoading(false)
      }
      
      
      };

      const handleSelectChange = (selectedOption) => {
        setSelectedJob(selectedOption.value); // Update the selected job
        fetchreportData(selectedOption.value);
        const filteredRows = searchData.filter((row) => row.jobdesss === selectedOption.label);
        setRowsData(filteredRows);
        setCurrentPage(1); // Reset to the first page when the job changes
    };

      const CustomPagination = () => (
        <div className="analysis-report" style={{  width:'100%', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
          <div style={{ float:'left', margin:'8px 0 0 8px' }}>
          Showing {Math.min((currentPage - 1) * entriesToShow + 1, rowsData.length)} to {Math.min(currentPage * entriesToShow, rowsData.length)} of {rowsData.length} entries
          </div>
          <div style={{ display: 'flex', alignItems: 'center',float:'right'}}>
            <Typography style={{ marginRight: '8px' }}>Rows</Typography>
            <TextField
              select
              variant="outlined"
              size="small"
              value={entriesToShow}
              onChange={(e) => setEntriesToShow(e.target.value)}
              className="analysis-menu"
              style={{ width: '150px' }} // Reduced the width
            >
              {[5, 10, 25, 50, 100].map((option) => (
                <MenuItem key={option} value={option} >
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {/* <Typography style={{ margin: '0 8px' }}>Entries</Typography> */}
            <Pagination
              color="primary"
              count={Math.ceil(rowsData.length / entriesToShow)}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              sx={{width: '100%'}}
            />
          </div>
        </div>
      );
    
    return(
        <Box sx={{position:'relative'}}>
            <Card sx={{padding:'1rem'}}>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'} alignContent={'center'}>
                <Grid item xs={12} sm={3} md={3} className="analysis_report_drop">
                  <FormControl fullWidth sx={{ top: '3px' }}>
                    <SelectDropDown
                      valueData={jobs}
                      value={selectedJob}
                      onJobChange={handleSelectChange}
                      placeholder="Select Id-Job"
                    />
                  </FormControl>
                </Grid>
                  <Grid item xs={12} sm={3} md={3}>
         
                      <TextField
                        label="Search"
                        variant="outlined"
                        sx={{width: '100%'}}
                        value={searchTerm}
                        onChange={(e) => handleFilter(e.target.value)}
                        style={{ marginRight: '8px' }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
      
                  </Grid>
                  <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <Grid item xs={12}>
                    <div>
                    {dataLaoding && 
                      <div style={{position: 'absolute', zIndex: 1025, background: 'rgba(255,255,255,0.5)', width: '100%', height:'100%', top:'0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                     
                      
                      <CircuarLoader />
                      </div>
                      }
                      
                        <DataGrid
                            rows={rowsData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}
                            columns={columns}
                            autoHeight
                            getRowId={getRowId}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            pagination
                            paginationMode="server"
                            className="analysis-report-table"
                            autoPageSize
                            components={{
                            NoRowsOverlay: () => (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.8)' }}>
                                 {!isLoading && rowsData.length === 0 ? (
                                    <Typography>No data available</Typography>
                                  ) : (
                                    isLoading && <Loader/>
                                  )}
                              </div>
                              ),
                              Pagination: CustomPagination,
                            }}
                        />
                    </div>
                    </Grid>
                  </Grid>

                
               
                </Grid>
            </Card>
            
            <PdfPrintPreview />
        </Box>
        
    )
}

export default PreviousReport